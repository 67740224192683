import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function makePrototypeFlight() {
  return {
    fromCode: "",
    toCode: "",
    travelClass: "economy",
    errors: {}
  };
}

class ImpactReportForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      travelersCount: this.props.flights
        ? this.props.flights[0].travelersCount
        : 1,
      flights: this.props.flights ? this.props.flights : [makePrototypeFlight()]
    };
  }

  handlePassengersChange = event => {
    this.setState({ travelersCount: event.target.value });
  };

  handleFlightChange = (index, flight) => {
    const flights = [].concat(this.state.flights);

    flights[index] = flight;

    this.setState({ flights });
  };

  handleFlightRemove = (index, event) => {
    event.preventDefault();

    let flights = this.state.flights
      .slice(0, index)
      .concat(this.state.flights.slice(index + 1));

    // Clicking remove on the last flight effectively resets it
    if (!flights.length) flights = [makePrototypeFlight()];

    this.setState({ flights });
  };

  handleAddFlight = event => {
    event.preventDefault();

    const lastFlight = this.state.flights[this.state.flights.length - 1];

    this.setState({
      flights: this.state.flights.concat([
        {
          ...makePrototypeFlight(),

          // Assume the newly added flight continues the itinerary from the last
          // airport in the same travel class
          fromCode: lastFlight.toCode,
          travelClass: lastFlight.travelClass
        }
      ])
    });
  };

  render() {
    const firstFlightErrors = this.state.flights[0].errors;

    return (
      <form
        className={"form-container"}
        action={this.props.submitUrl}
        method="POST"
      >
        <label className={"form-label"}>
          Passengers
          <input
            className={classNames("form-field", {
              "form-field-error": !!firstFlightErrors.travelersCount
            })}
            type="number"
            min="1"
            step="1"
            placeholder="#"
            required
            value={this.state.travelersCount || ""}
            onChange={this.handlePassengersChange}
          />
          <FieldErrorMessage errors={firstFlightErrors.travelersCount} />
        </label>

        <Fragment>
          {this.state.flights.map((f, i) => (
            <ImpactReportFlight
              key={i}
              index={i}
              flight={f}
              travelersCount={this.state.travelersCount}
              showRemoveButton={this.state.flights.length > 1}
              onChange={this.handleFlightChange.bind(this, i)}
              onRemove={this.handleFlightRemove.bind(this, i)}
            />
          ))}
        </Fragment>

        <a id={"add-flight-btn"} className={"add-flight-btn"} onClick={this.handleAddFlight}>
          Add another flight
        </a>

        <input
          type="hidden"
          name={this.props.csrfToken.name || ""}
          value={this.props.csrfToken.value || ""}
        />

        <input type="hidden" name="source" value={this.props.source || ""} />
        <input type="hidden" name="event_id" value={this.props.eventId || ""} />
        <input type="hidden" name="user_id" value={this.props.userId || ""} />

        <input
          className={"btn-simple btn-lg btn-green btn-mobile form-btn"}
          type="submit"
          value="Create Impact Report"
          id="create-impact-report-btn"
        />
      </form>
    );
  }
}

class ImpactReportFlight extends React.Component {
  handleChange = (prop, event) => {
    this.props.onChange({
      ...this.props.flight,
      [prop]: event.target.value
    });
  };

  componentDidMount() {
    // Add Select2 to from_iata_code
    function addSelect2(id, placeholder){
      $(`#${id}`).select2({
        ajax: {
          url: "/search_flight_airport",
          dataType: 'json',
          delay: 250,
          data: function (params) {
            return {
              q: params.term,
            };
          },
          processResults: function (data, params) {
            return {
              results: data.airports,
            };
          },
          // cache: true
        },
        placeholder: placeholder,
        theme: "bootstrap-5",
        minimumInputLength: 3,
        templateResult: formatAirport,
        templateSelection: formatAirportSelection
      });
    }

    function formatAirport(airport) {
      return `${airport.name} (${airport.iata_code})`
    };

    function formatAirportSelection (airport) {
      return airport.name || airport.text;
    }

    // Add Select2 to from_iata_code
    addSelect2('flights_0_from_code', 'Airport code eg: LGW')
    addSelect2('flights_0_to_code', 'Airport code eg: LAX')

  }

  render() {
    const flight = this.props.flight;
    const errors = flight.errors;

    return (
      <div>
        <div className={"form-fields-inline"}>
          <input
            type="hidden"
            name={`flights[${this.props.index}][travelers_count]`}
            value={this.props.travelersCount || ""}
          />
          <label className={"form-label"}>
            From:
            <select
              id="flights_0_from_code"
              className={classNames("form-field iata-code-field", {
                "form-field-error": !!errors.fromCode
              })}
              name={`flights[${this.props.index}][from_code]`}
              value={flight.fromCode || ""}
              onChange={this.handleChange.bind(this, "fromCode")}
              required
            ></select>

            <FieldErrorMessage errors={errors.fromCode} />
          </label>

          <label className={"form-label"}>
            To:
            <select
              id="flights_0_to_code"
              className={classNames("form-field iata-code-field", {
                "form-field-error": !!errors.toCode
              })}
              name={`flights[${this.props.index}][to_code]`}
              value={flight.toCode || ""}
              onChange={this.handleChange.bind(this, "toCode")}
              required
            ></select>
            <FieldErrorMessage errors={errors.toCode} />
          </label>
        </div>

        <label className={"form-label"}>
          Class:
          <select
            className={"form-field form-select"}
            name={`flights[${this.props.index}][travel_class]`}
            value={flight.travelClass || ""}
            onChange={this.handleChange.bind(this, "travelClass")}
          >
            <option value="economy">Economy</option>
            <option value="business">Business</option>
            <option value="first">First</option>
          </select>
        </label>

        {this.props.showRemoveButton && (
          <div className={"remove-flight"}>
            <button
              className={
                "btn-simple btn-md btn-black btn-mobile remove-flight-btn"
              }
              id={'remove-flight-btn'}
              onClick={this.props.onRemove}
            >
              Remove Flight
            </button>
          </div>
        )}
      </div>
    );
  }
}

function FieldErrorMessage(props) {
  if (props.errors) {
    return (
      <span className={"form-field-error-msg"}>{props.errors.join(", ")}</span>
    );
  } else {
    return <Fragment />;
  }
}

ImpactReportForm.propTypes = {};

export default ImpactReportForm;
