import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectStripe, CardElement } from 'react-stripe-elements';

class PaymentForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitting: false,
      email: ''
    };

    this.redirectForm = React.createRef();
  }
  handleSubmit = event => {
    event.preventDefault();

    // Do nothing if we're already submitting the form
    if (this.state.submitting) return;

    this.setState({ submitting: true });

    this.props.stripe
      .handleCardPayment(this.props.intentClientSecret)
      .then(this.handlePaymentResult);
  };
  handlePaymentResult = result => {
    if (result.error) {
      this.setState({ submitting: false });

      alert(result.error.message);
    } else {
      this.redirectForm.current.submit();
    }
  };
  handleEmailChange = event => {
    this.setState({ email: event.target.value });
  };
  render() {
    return (
      <React.Fragment>
        <form className="form-container" onSubmit={this.handleSubmit}>
          {this.props.collectEmail && (
            <Fragment>
              <label className="form-label billing-form-label">
                Email Address for Receipt
              </label>

              <input
                type="email"
                value={this.state.email}
                placeholder="email@address.com"
                onChange={this.handleEmailChange}
                className="form-field"
                required
              />
            </Fragment>
          )}

          <label className="form-label billing-form-label">
            Billing Details
          </label>
          <CardElement disabled={this.state.submitting} />

          <input
            type="submit"
            value={
              this.state.submitting ? 'Charging...' : 'Pay and Make Up For It'
            }
            disabled={this.state.submitting}
            className={'btn-simple btn-lg btn-green btn-mobile form-btn'}
            id="payment-form-btn"
          />
        </form>
        <form
          method="post"
          action={this.props.confirmUrl}
          ref={this.redirectForm}
          style={{ display: 'none' }}
        >
          <input type="hidden" name="_method" value="PUT" />
          <input
            type="hidden"
            name={this.props.csrfToken.name}
            value={this.props.csrfToken.value}
          />
          {this.props.collectEmail && (
            <input type="hidden" name="email" value={this.state.email} />
          )}
        </form>
      </React.Fragment>
    );
  }
}

PaymentForm.propTypes = {
  intentClientSecret: PropTypes.string,
  confirmUrl: PropTypes.string,
  csrfToken: PropTypes.object,
  collectEmail: PropTypes.bool
};
export default injectStripe(PaymentForm);
