import React from 'react';
import PropTypes from 'prop-types';

class CopyButton extends React.Component {
  static TIMEOUT = 3000;

  constructor(props) {
    super(props);

    this.state = {
      success: false,
      timeout: null
    };
  }

  onClick = event => {
    navigator.clipboard
      .writeText(this.props.value)
      .then(() => {
        if (this.state.timeout) clearTimeout(this.state.timeout);

        this.setState({
          success: true,
          timeout: setTimeout(
            () => this.setState({ success: false, timeout: null }),
            CopyButton.TIMEOUT
          )
        });
      })
      .catch(err => {
        alert("Sorry, couldn't copy to clipboard");
      });
  };

  isSupported() {
    return !!navigator.clipboard;
  }

  render() {
    const text = this.state.success
      ? this.props.successText
      : this.props.defaultText;

    const style = this.isSupported() ? {} : { display: 'none' };

    return (
      <button
        style={style}
        id={this.props.id}
        className={this.props.className}
        onClick={this.onClick}
      >
        {text}
      </button>
    );
  }
}

CopyButton.propTypes = {
  value: PropTypes.string,
  successText: PropTypes.string,
  defaultText: PropTypes.string,
  className: PropTypes.string
};
export default CopyButton;
