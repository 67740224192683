import React from 'react';
import PropTypes from 'prop-types';
import { StripeProvider, Elements } from 'react-stripe-elements';

import PaymentForm from './PaymentForm';

class Billing extends React.Component {
  render() {
    return (
      <StripeProvider apiKey={this.props.stripePublicKey}>
        <Elements>
          <PaymentForm
            intentClientSecret={this.props.intentClientSecret}
            confirmUrl={this.props.confirmUrl}
            csrfToken={this.props.csrfToken}
            collectEmail={this.props.collectEmail}
          />
        </Elements>
      </StripeProvider>
    );
  }
}

Billing.propTypes = {
  stripePublicKey: PropTypes.string,
  intentClientSecret: PropTypes.string,
  confirmUrl: PropTypes.string,
  csrfToken: PropTypes.object,
  collectEmail: PropTypes.bool,
};
export default Billing;
